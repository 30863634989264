import type { HttpResponse } from '@wix/yoshi-flow-editor';
import { getGlobals } from '../../utils/globals.utils';

const INSTANCE_HISTORY_SERVER_BASE_URL = '/_serverless/instance-id-history';
const GET_INSTANCE_END_POINT = '/get-instance-history';

export interface InstanceHistoryItem {
  instanceId: string;
  originId: string;
  lastConsentedOriginId: string;
  key: string;
}

// TODO: this is here because of viewer.controller
export const getLastConsentedOrigin = async (
  baseUrl: string,
): Promise<string | undefined> => {
  const { httpClient, instanceId } = getGlobals();

  // First check if the instance has already been updated and has a lastCOnsented of itself, if not, check the origin ID. return the last consented of them
  try {
    const responseFromInstance: HttpResponse<InstanceHistoryItem> =
      await httpClient.get(
        `${baseUrl}${INSTANCE_HISTORY_SERVER_BASE_URL}${GET_INSTANCE_END_POINT}/${instanceId}`,
      );
    if (responseFromInstance?.data?.lastConsentedOriginId !== undefined) {
      return responseFromInstance?.data?.lastConsentedOriginId;
    }
  } catch (err) {
    console.error('error getting instance history', {
      err,
    });
  }
};
